<template>
    <div>
        <page-header
            :title="pageHeaderTitle"
            data-testid="close-account-wire-transfer-header"
            show-back
            @onClose="$router.go(-1)"
        />
        <div class="container">
            <div
                v-if="!isZeroBalancePayoff"
                class="card border-0 pb-2"
                data-testid="close-account-wire-transfer-details"
            >
                <div class="d-flex justify-content-between mb-2">
                    <div class="text-start">
                        <span> Routing Number </span>
                    </div>
                    <div class="text-end">
                        <span class="text-muted">
                            {{ wireRoutingNumber }}
                        </span>
                    </div>
                </div>

                <div class="d-flex justify-content-between mb-2">
                    <div class="text-start">
                        <span> Account Number </span>
                    </div>
                    <div class="text-end">
                        <span class="text-muted">
                            {{ wireAccountNumber }}
                        </span>
                    </div>
                </div>

                <div class="d-flex justify-content-between mb-2">
                    <div class="text-start">
                        <span> Beneficiary </span>
                    </div>
                    <div class="text-end">
                        <span class="text-muted">
                            {{ wireBeneficiary }}
                        </span>
                    </div>
                </div>

                <div class="d-flex justify-content-between mb-2">
                    <div class="text-start">
                        <span> Wire Amount </span>
                    </div>
                    <div class="text-end">
                        <span class="text-muted">
                            {{ toFormattedUSD(payoffDetails.totalPayoffAmount) }}
                        </span>
                    </div>
                </div>

                <div class="d-flex justify-content-between">
                    <div class="text-start">
                        <span> Add As Note </span>
                    </div>
                    <div class="text-end">
                        <span class="text-muted">
                            {{ wireTransferNote }}
                        </span>
                    </div>
                </div>
            </div>
            <div
                v-if="!isAccountPendingCloseOrPayoff"
                data-testid="close-account-wire-transfer-confirm"
            >
                <form
                    @submit.prevent="handleCloseAccount"
                    class="mt-2"
                >
                    <label
                        v-if="!isZeroBalancePayoff"
                        class="d-flex flex-row py-1"
                        data-testid="close-account-wire-transfer-confirm-checkbox-1"
                        for="confirm-wire"
                    >
                        <div class="pe-1">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                name="confirm-wire"
                                id="confirm-wire"
                                v-model="confirmWire"
                            >
                        </div>
                        <div class="d-flex flex-column">
                            <span class="fw-bold"> {{ $t('pages.closeAccount.wireTransfer.confirmWireText') }} </span>
                            <span v-html="$t('pages.closeAccount.wireTransfer.confirmWireSubText', { amount: toFormattedUSD(payoffDetails.totalPayoffAmount) })" />
                        </div>
                    </label>
                    <label
                        class="d-flex flex-row py-1"
                        data-testid="close-account-wire-transfer-confirm-checkbox-2"
                        for="confirm-close"
                    >
                        <div class="pe-1">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                name="confirm-close"
                                id="confirm-close"
                                v-model="confirmCloseOrKeepCard"
                            >
                        </div>
                        <div
                            class="d-flex flex-column"
                            v-if="shouldCloseAccountKeepCard"
                        >
                            <span
                                class="fw-bold"
                                data-testid="close-account-wire-transfer-confirm-keep-card-checkbox-text"
                            >
                                {{ $t('pages.closeAccount.wireTransfer.confirmKeepCardText', { limitedModeCreditLimit: toFormattedUSDNoCents(this.limitedModeCreditLimit) }) }}
                            </span>
                            <span v-html="$t('pages.closeAccount.wireTransfer.confirmKeepCardSubText', { limitedModeCreditLimit: toFormattedUSDNoCents(this.limitedModeCreditLimit) })" />
                        </div>
                        <div
                            class="d-flex flex-column"
                            v-else
                        >
                            <span
                                class="fw-bold"
                                data-testid="close-account-wire-transfer-confirm-close-checkbox-text"
                            > {{ $t('pages.closeAccount.wireTransfer.confirmCloseText') }} </span>
                            <span v-html="$t('pages.closeAccount.wireTransfer.confirmCloseSubText')" />
                        </div>
                    </label>
                    <div class="mt-4">
                        <div v-if="errorText">
                            <div
                                class="alert alert-warning text-center"
                                role="alert"
                            >
                                <span v-html="$t('global.errors.genericRetry')" />
                            </div>
                        </div>
                        <base-button
                            :disabled="(!isZeroBalancePayoff && !confirmWire) || !confirmCloseOrKeepCard"
                            :submitting="isSubmitting"
                            button-classes="btn btn-primary mb-4"
                            data-testid="close-account-wire-transfer-close-account-button"
                            @click="handleCloseAccount"
                        >
                            {{ this.buttonText }}
                        </base-button>
                    </div>
                </form>
            </div>
            <div
                v-if="isZeroBalancePayoff && isAccountPendingCloseOrPayoff"
                class="text-center"
                data-testid="close-account-wire-transfer-nothing-to-do-notice"
            >
                <img
                    src="@/assets/images/pages/card/Celebration.svg"
                    alt="Pending close"
                    height="112"
                    width="112"
                >
                <h5
                    class="mt-2"
                    data-testid="close-account-wire-transfer-nothing-to-do-text"
                >
                    <span>
                        {{ $t('pages.closeAccount.wireTransfer.nothingToDoText') }}
                    </span>
                </h5>
                <base-button
                    button-classes="btn btn-primary mt-2"
                    data-testid="close-account-wire-transfer-nothing-to-do-button"
                    @click="returnToRoot"
                >
                    Got it
                </base-button>
            </div>
        </div>
    </div>
</template>

<script>
    import PageHeader from '@/components/PageHeader'
    import { navigation } from '@/mixins/navigation'
    import { logger } from '@/utils/logger'
    import { RouteNames } from '@/routes/router'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import BaseButton from '@/components/base/BaseButton'
    import { PAYOFF_WIRE_TRANSFER_DETAILS } from '@/data/constants'
    import { mapGetters } from 'vuex'
    import format from '@/mixins/format'
    import { generateLegalDocument, initiateAccountClosureRequest } from '@/services/api'
    import { AccountClosureType, AvenAccountStanding } from '@/store'
    import generic from '@/utils/generic'

    export default {
        name: 'CloseAccountWireTransferDetails',
        mixins: [format, navigation],
        components: {
            PageHeader,
            BaseButton,
        },
        mounted() {
            this.$logEvent('view_close_account_wire_transfer_details')
        },
        data() {
            return {
                wireRoutingNumber: PAYOFF_WIRE_TRANSFER_DETAILS.routing_number,
                wireAccountNumber: PAYOFF_WIRE_TRANSFER_DETAILS.account_number,
                wireBeneficiary: PAYOFF_WIRE_TRANSFER_DETAILS.beneficiary_name,
                note: '',
                errorText: null,
                confirmWire: false,
                confirmCloseOrKeepCard: false,
                isSubmitting: false,
            }
        },
        computed: {
            ...mapGetters({
                customerLastName: 'lastName',
                customerAccountNumber: 'accountNumber',
                payoffDetails: 'payoffDetails',
                isZeroBalancePayoff: 'isZeroBalancePayoff',
                closeAccountReason: 'closeAccountReason',
                accountStanding: 'avenAccountStanding',
                shouldCloseAccountKeepCard: 'shouldCloseAccountKeepCard',
                limitedModeCreditLimit: 'limitedModeCreditLimit',
            }),
            isAccountPendingCloseOrPayoff: function () {
                return this.accountStanding === AvenAccountStanding.pendingClose || this.accountStanding === AvenAccountStanding.pendingPayoffThenLimitedMode
            },
            pageHeaderTitle: function () {
                if (this.isZeroBalancePayoff && this.isAccountPendingCloseOrPayoff) {
                    return ''
                } else if (this.isZeroBalancePayoff) {
                    return 'Confirmation'
                } else {
                    return 'Wire Transfer Details'
                }
            },
            wireTransferNote: function () {
                return `Aven Payoff  ${this.customerLastName}  ${this.customerAccountNumber}`
            },
            buttonText: function () {
                return this.shouldCloseAccountKeepCard ? 'Continue' : 'Close Account'
            },
        },
        methods: {
            handleCloseAccount: async function () {
                const requestType = this.shouldCloseAccountKeepCard ? AccountClosureType.KEEP_CARD : AccountClosureType.PERMANENT_CLOSE
                this.$logEvent('click_close_account')
                try {
                    this.isSubmitting = true
                    this.errorText = ''
                    const { data } = await initiateAccountClosureRequest(generic.getCurrentJSDateInNycTz(), requestType, this.closeAccountReason)
                    if (data.success) {
                        this.$logEvent('event_submit_close_account_success', { requestType, requestReason: this.closeAccountReason })
                        await this.$store.dispatch('updateAccountOverview')
                        await this.$router.push({ name: RouteNames.CARD })
                    } else {
                        this.$logEvent('event_submit_close_account_fail', { requestType, requestReason: this.closeAccountReason, error: data.error })
                        logger.info(`Failed to close the account: ${data.error}`)
                        this.errorText = data.error
                    }
                    if (this.shouldCloseAccountKeepCard) {
                        // generate the new account agreement
                        await generateLegalDocument('AccountAgreementHomeLimitedMode')
                    }
                } catch (error) {
                    this.$logEvent('event_submit_close_account_error')
                    this.errorText = ApiErrorHandler(error)
                } finally {
                    this.isSubmitting = false
                }
            },
        },
    }
</script>
